:root {
	--color-noticebox: #0C111E;
	--color-notice-summary: #c2c2c2;

	--width-noticebox: 1152px;
	--width-thumb: 256px;
	--height-thumb: 140px;
}

.noticeBox {
	border-radius: 0.25rem;
	overflow: hidden;
	margin-bottom: 1rem;
	width: var(--width-noticebox);
	display: flex;
	background-color: var(--color-noticebox);
	outline: 2px solid transparent;

	moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.noticeBox:hover {
	cursor: pointer;
	outline: 2px solid #5c5c5c;

	moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.noticeThumbBox {
	padding: 1rem;
	width: var(--width-thumb);
	height: var(--height-thumb);
	overflow: hidden;
	display: block;
}

.noticeThumb {
	width: var(--width-thumb);
	height: var(--height-thumb);
}

.noticeTextsBox {
	width: calc(var(--width-noticebox) - var(--width-thumb) - (1rem * 4));
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
}

.noticeTitle {
	font-size: 1.5rem;
	font-family: 'Noto Sans KR', sans-serif;
	font-weight: 300;
	margin-bottom: 0.5rem;
}

.noticeSummary {
	font-size: 1.2rem;
	color: var(--color-notice-summary);
	font-family: 'Noto Sans KR', sans-serif;
	font-weight: 100;
}

.noticeData {
	display: flex;
	flex-direction: row;
	justify-content: flex-end
}

.noticeData p {
	color: var(--color-notice-summary);
}