:root {
	--nav-for-color: white;

	--nav-background: transparent;
	--section-background: transparent;
	--child-background: transparent;

	--color-hover: rgb(255, 0, 191);
}

a:link {
	text-decoration: none;
	color: var(--nav-for-color);
}

a:visited {
	text-decoration: none;
	color: var(--nav-for-color);
}

a:hover {
	text-decoration: none;
	color: var(--nav-for-color);
}

a:active {
	text-decoration: none;
	color: var(--nav-for-color);
}

.nav {


	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 1rem;
	padding: 2rem;
	padding-left: 10rem;
	padding-right: 10rem;

	position: relative;
	z-index: 999;

	color: var(--nav-for-color);

	font-family: 'Noto Sans KR', sans-serif;
	font-weight: 700;
}

.logoSection {
	background-color: var(--section-background);

}

.logo {
	display: block;
	background-color: var(--child-background);
	margin: 0px 12px;
}

.logoImage {
	height: 3rem;
	display: block;
}

.linkSection {
	display: flex;
	background-color: var(--section-background);
}

.link {
	display: block;
	background-color: var(--child-background);
	margin: 0px 1rem;
	color: var(--nav-for-color);

	text-decoration: none;

	moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.link:hover {
	color: var(--color-hover);
	cursor: pointer;

	moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.shortCutSection {
	display: flex;
	background-color: var(--section-background);
}

.shortCut {
	display: block;
	background-color: var(--child-background);
	margin: 0px 12px;

	moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.shortCut:hover {
	color: var(--color-hover);
	cursor: pointer;

	moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}