:root {
	--color-h1: rgb(255, 255, 255);
	--color-h2: rgb(255, 255, 255);
	--color-h3: rgb(255, 255, 255);
	--color-h4: rgb(255, 255, 255);
	--color-h5: rgb(255, 255, 255);
	--color-p: rgb(150, 150, 150);
}

.articleContainer {
	display: flex;
	justify-content: center;
}

.article {
	margin-top: 3rem;
	margin-bottom: 12rem;
	border-radius: 0.5rem;
	background-color: #0d1117;
	border: 1px solid #30363d;
	width: 1152px;
	display: flex;
	flex-direction: column;
	padding: 5rem;
	font-family: 'Noto Sans KR', sans-serif;
}

.article h1 {
	font-size: 2.5rem;
	font-weight: 700;
	color: var(--color-h1);
	margin-bottom: 3rem;
}

.article h2 {
	font-size: 1.75rem;
	font-weight: 500;
	color: var(--color-h1);
	margin-top: 3rem;
	margin-bottom: 1rem;
}

.article h3 {
	font-size: 1.25rem;
	font-weight: 500;
	color: var(--color-h1);
	margin-bottom: 0.5rem;
	margin-top: 1rem;
}

.article h4 {
	font-size: 1rem;
	font-weight: 300;
	color: var(--color-h1);
	margin-bottom: 0.5rem;
	margin-top: 1rem;
}

.article hr {
	margin-bottom: 2rem;
}

.article p {
	font-size: 1rem;
	font-weight: 100;
	margin-bottom: 0.25rem;
	color: var(--color-p);
}

.article img {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.article ul {
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 2rem;
}