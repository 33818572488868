:root {
	--margin-h6: 50%;
	--color-article-background: rgb(71, 71, 71);
	--color-Signup-cover: #12182B;
}

.articleBox {
	background-color: var(--color-article-background);
	position: absolute;
	top: 0;

	width: 100vw;
	height: 100vh;

	font-size: 2rem;

	z-index: 0;

	overflow: hidden;
}

.stack {
	position: relative;
	width: 100%;
	height: 100%;
}

.articleCover {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--color-Signup-cover);
	z-index: 30;
}

.articleContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Noto Sans KR', sans-serif;

	color: var(--color-main);
	z-index: 40;
}

.signUpConteiner {
	padding: 2rem;
	padding-top: 4rem;
	border-radius: 0.5rem;
	width: 32rem;
}

.signUpConteiner h1 {
	font-size: 3rem;
	font-weight: 100;
	text-align: center;
	margin-bottom: 4rem;
}

.signUpConteiner h2 {
	font-size: 2rem;
	font-weight: 400;
	text-align: center;
	margin-bottom: 2rem;
}

.signUpConteiner div {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.signUpConteiner div label {
	font-size: 0.75rem;
	font-weight: 100;
	color: rgb(179, 179, 179);
	margin-bottom: 0.5rem;
}

.signUpConteiner div .inputbox{
	height: 3rem;
	background-color: #0C111E;
	border: none;
	border-radius: 0.5rem;
	flex-direction: row;
	margin:0;
}

.signUpConteiner div input {
	height: 3rem;
	background-color: #0C111E;
	border: none;
	border-radius: 0.5rem;
	padding: 0 1.5rem;
	color: white;
	font-size: 1rem;
	flex: 90%;
}

.signUpConteiner div input:focus {
	outline: none;
}

.signUpConteiner div .isvalid{
	height: 1rem;
	width: 1rem;
	padding-right: 1rem;
	padding-top:1rem;
	visibility: hidden;
}

/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
	/* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 32rem;
	height: 1rem;
	color: #bbb;
	font-size: small;
	text-align: center;
	border-radius: 6px;

	/* Position the tooltip text - see examples below! */
	position: relative;
	z-index: 1;
}
.tooltip:focus-within .tooltiptext {
	visibility: visible;
}

.tasktooltip {
	position: absolute;
	margin-top: 3rem;
	right:0;
  background-color: #0C111E;
  border: rgb(122, 122, 122) solid 1px;
  border-radius: 5px;
  color: #bbb;
  font-size: 12px;
  font-weight: 500;
  height: auto;
  letter-spacing: -0.25px;
  padding: 5px 11px;
  width: fit-content;
  z-index: 100;
	visibility: hidden;
}

.tasktooltip::after {
  border-color: #0C111E transparent;
  border-style: solid;
  border-width: 0 6px 8px 6.5px;
  content: '';
  display: block;
  right:1rem;
  position: absolute;
  top: -7px;
  width: 0;
  z-index: 1;
}

.tasktooltip::before {
  border-color: #bbb transparent;
  border-style: solid;
  border-width: 0 6px 8px 6.5px;
  content: '';
  display: block;
  right: 1rem;
  position: absolute;
  top: -8px;
  width: 0;
  z-index: 0;
}

.signUpConteiner button {
	width: 100%;
}

.hidden {
	display: none;
}

.Button {
	font-family: 'Noto Sans KR', sans-serif;
	font-size: 1rem;
	font-weight: 700;
	color: #fff;
	cursor: pointer;
	height: 3rem;
	text-align: center;
	border: none;
	background-size: 300% 100%;
	margin-top: 1rem;

	border-radius: 0.5rem;
	moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;

	background-image: linear-gradient(to right, #F59E33, #C9338B, #C9338B, #312880);
	box-shadow: 0 4px 15px 0 rgba(168, 79, 149, 0.75);
}

.Button:hover {
	background-position: 100% 0;
	moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.Button:focus {
	outline: none;
}