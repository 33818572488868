:root {
	--margin-h6: 50%;
	--color-article-background: rgb(255, 84, 84);
	--colorA-cover: rgba(0, 0, 0, 0.5);
}

.Home {
	position: relative;
}

.articleBox {
	background-color: var(--color-article-background);
	position: absolute;
	top: 0;

	width: 100vw;
	height: 100vh;

	font-size: 2rem;

	z-index: 0;

	overflow: hidden;
}

.stack {
	position: relative;
	width: 100%;
	height: 100%;
}

.articleImage {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('');
	background-size: cover;
	z-index: 10;
}

.articleVideo {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 20;
}

.articleCover {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--colorA-cover);
	z-index: 30;
}

.articleContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Noto Sans KR', sans-serif;

	color: var(--color-main);
	z-index: 40;
}

.articleContainer h1 {
	text-align: center;
}

.articleContainer h6 {
	font-weight: 500;
	font-size: 1rem;
	text-align: center;
	margin-top: 32px;
	margin-left: calc(var(--margin-h6) / 2);
	margin-right: calc(var(--margin-h6) / 2);
}

.Buttons {
	margin: 10%;
	margin-top: 1%;
	margin-bottom: 1%;
	text-align: center;
}

.Button {
	width: 200px;
	font-family: 'Noto Sans KR', sans-serif;
	font-size: 1rem;
	font-weight: 700;
	color: #fff;
	cursor: pointer;
	margin: 20px;
	height: 55px;
	text-align: center;
	border: none;
	background-size: 300% 100%;

	border-radius: 50px;
	moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;

	background-image: linear-gradient(to right, #F59E33, #C9338B, #C9338B, #312880);
	box-shadow: 0 4px 15px 0 rgba(168, 79, 149, 0.75);
}

.Button:hover {
	background-position: 100% 0;
	moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.Button:focus {
	outline: none;
}